import { ReactNode, useId, useImperativeHandle, useRef, useState } from "react";
import joinClassNames from "classnames";

import CircledWarning20Icon from "icons/circled-warning20.svg?react";

import {
  TextareaRefProps,
  InputRefProps,
  InputElementTypes,
} from "../../duck/types";

import classes from "./styles/classes.module.scss";

function BlankInput(props: InputRefProps): ReactNode;
function BlankInput(props: TextareaRefProps): ReactNode;

function BlankInput({
  isBlock,
  as,
  size = "sm",
  before,
  after,
  className,
  label,
  hint,
  classNames = {},
  innerRef,
  error,
  onChange,
  ...props
}: InputRefProps & TextareaRefProps): ReactNode {
  const inputRef = useRef(null);
  const id = useId();
  const [isFocused, setIsFocused] = useState(
    Boolean(!props.disabled && props.autoFocus),
  );

  const isError = Boolean(error);

  useImperativeHandle(innerRef, () => inputRef.current);

  const InputComponent = (as as InputElementTypes) ?? "input";

  return (
    <div
      data-type="input"
      data-size={size}
      className={joinClassNames(classes.sizePresets, classNames.wrapper)}
    >
      {label && (
        <label
          className={joinClassNames(classes.label, classNames.label)}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div
        data-focused={isFocused}
        data-block={isBlock}
        data-errored={isError}
        data-readonly={props.readOnly}
        className={joinClassNames(classes.focusWrapper, className)}
        onMouseDown={event => {
          if (event.target !== inputRef.current) {
            event.preventDefault();
          }

          if (!isFocused) {
            inputRef.current.focus();
          }
        }}
      >
        {before}
        <InputComponent
          className={joinClassNames(
            classes.blank,
            classes.input,
            classNames.input,
          )}
          ref={inputRef}
          id={id}
          data-errored={isError}
          onChange={
            props.pattern
              ? event => {
                  if (event.target.validity.patternMismatch) {
                    return;
                  }

                  onChange(event);
                }
              : onChange
          }
          {...props}
          onFocus={event => {
            setIsFocused(true);
            props.onFocus?.(event);
          }}
          onBlur={event => {
            setIsFocused(false);
            props.onBlur?.(event);
          }}
        />
        {after}
      </div>
      {hint && (
        <label
          className={joinClassNames(classes.hint, classNames.hint)}
          htmlFor={id}
        >
          {hint}
        </label>
      )}
      {error && (
        <div
          className={joinClassNames(
            classes.errorMessage,
            classNames.errorMessage,
          )}
        >
          <CircledWarning20Icon />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}

export default BlankInput;
